import React from "react";
import { Link } from "gatsby";

// @ts-expect-error false positive image import failure
import imgL1Logo from "../../assets/image/logos/dark.png";
// @ts-expect-error false positive image import failure
import imgL1LogoWhite from "../../assets/image/logos/light.png";

interface Props {
  white: boolean;
  className?: string;
  height?: unknown;
  [propName: string]: unknown;
}

const Logo: React.FC<Props> = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      {white ? (
        <img style={{ maxHeight: 30 }} src={imgL1LogoWhite} alt="" />
      ) : (
        <img style={{ maxHeight: 30 }} src={imgL1Logo} alt="" />
      )}
    </Link>
  );
};

export default Logo;
