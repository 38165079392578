import React from "react";
import styled from "styled-components";
import {
  color,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  shadow,
  ShadowProps,
  style,
  ResponsiveValue,
} from "styled-system";
import { device } from "../../utils";
import CSS from "csstype";

const textColor = style({
  prop: "textColor",
  cssProperty: "color",
  key: "colors",
});

interface StyledProps extends SpaceProps, TypographyProps, ShadowProps {
  textColor?: ResponsiveValue<CSS.ColorProperty>;
  bg?: string;
  css?: string;
}

const SectionTitle = styled.h1<StyledProps>`
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  margin-bottom: 16px;

  @media ${device.sm} {
    font-size: 50px;
    line-height: 62px;
  }

  @media ${device.lg} {
    font-size: 50px;
    line-height: 70px;
    margin-bottom: 30px;
  }

  ${color}
  ${textColor};
  ${space};
  ${typography};
  ${shadow};
`;

const HeroTitle = styled(SectionTitle)`
  font-size: 50px;
  line-height: 56px;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 76px;
    line-height: 84px;
  }

  @media ${device.xl} {
    font-size: 65px;
    line-height: 82px;
  }
`;

const CardTitle = styled.h4<StyledProps>`
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.66px;
  line-height: 28px;
  ${color}
  ${textColor};
  ${space};
  ${typography};
  ${shadow};
`;

interface Props extends StyledProps {
  variant?: unknown;
  className?: string;
}

const Title: React.FC<Props> = ({ variant, ...rest }) => {
  let TitleStyled = SectionTitle;

  switch (variant) {
    case "card":
      TitleStyled = CardTitle;
      break;
    case "hero":
      TitleStyled = HeroTitle;
      break;
    default:
      TitleStyled = SectionTitle;
  }

  return <TitleStyled color="heading" {...rest} />;
};

export default Title;
