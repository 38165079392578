import React from "react";
import styled from "styled-components";
import {
  space,
  SpaceProps,
  typography,
  TypographyProps,
  shadow,
  ShadowProps,
  style,
  ResponsiveValue,
} from "styled-system";
import CSS from "csstype";

const textColor = style({
  prop: "textColor",
  cssProperty: "color",
  key: "colors",
});

interface SpanStyledProps extends SpaceProps, TypographyProps, ShadowProps {
  textColor?: ResponsiveValue<CSS.ColorProperty>;
}

const SpanStyled = styled.span<SpanStyledProps>`
  ${textColor};
  ${space};
  ${typography};
  ${shadow};
`;

const Span: React.FC<SpanStyledProps> = ({ ...props }) => {
  return <SpanStyled {...props} />;
};

export default Span;
