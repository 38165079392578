interface Items{
  name:string;
  label:string;
  items?:Items[]
  isExternal?:boolean
}

export const menuItems:{name:string;label:string;items?:Items[];isExternal?:boolean}[] = [
  // {
  //   name: "demos",
  //   label: "Our Pitch",
  //   items: [
  //     { name: "bids-and-councils", label: "BIDs, CRPs & Councils" },
  //     { name: "shopping-centres-and-parks", label: "Shopping Centre & Parks" },
  //     { name: "retailers-hospitality", label: "Retailers & Hospitality" },
  //   ],
  // },
  // {
  //   name: "pages",
  //   label: "The Devices",
  //   items: [
  //     { name: "shopwatch-and-townlink", label: "ShopWatch & TownLink" },
  //     { name: "alert", label: "Alert! Crime Reporting App" },
  //     { name: "smart-radio", label: "Smart Radio & Cameras" },
  //     { name: "internal-communication", label: "Internal Communication" },
  //   ],
  // },
  {
    name: "contact",
    label: "Become a Reseller"
  },
  {
    name: "contact",
    label: "Contact Us",
  },
];
