import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Box, Text } from "../Core";
import Logo from "../Logo";
import { Link } from "gatsby";

const TitleStyled = styled(Title)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
`;

interface Theme {
  theme: DefaultTheme;
}

interface UlStyledProps extends Theme {
  color: keyof DefaultTheme["colors"];
}

const UlStyled = styled.ul<UlStyledProps>`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 2.25;
    a {
      color: ${({ theme, color }) => theme.colors[color]} !important;
      &:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.secondary} !important;
      }
    }
  }
`;

interface CopyRightAreaProps extends Theme {
  dark: boolean | 1 | 0;
}

const CopyRightArea = styled.div<CopyRightAreaProps>`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
        dark ? theme.colors.light : theme.colors.dark} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.secondary} !important;
      }
    }
  }
`;

interface Props {
  isDark: boolean;
}

const Footer: React.FC<Props> = ({ isDark = false }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg="#F7F7FB">
        <Container>
          <Box pt={6} pb={5}>
            <Row className=" d-flex align-items-start justify-content-center">
              <Col
                xl="2"
                sm="3"
                className=" mb-4 d-flex justify-content-center"
              >
                <Logo white={false} />
              </Col>
              <Col xl="2" sm="3" className="mb-4 text-center">
                <Link to="/contact">Become a Reseller</Link>
              </Col>

              <Col xl="2" sm="3" className="mb-4 text-center">
                <Link to="/contact">Contact Us</Link>
              </Col>

              <Col xl="2" sm="3" className="mb-4 text-center">
                <a href="https://portal.gotalk.co.uk">Portal Login</a>
              </Col>
            </Row>
          </Box>
          <CopyRightArea dark={0}>
            <Row className=" d-flex align-items-start justify-content-center">
              <Col md="5" className=" mb-0 mb-md-2  text-center">
                <p> &copy; 2022 GoTalk Ltd, All Rights Reserved</p>
              </Col>

              <Col
                md="4"
                className="d-inline-flex mb-0 mb-md-2 justify-content-center"
              >
                <Link to="/privacy-policy">
                  <Text fontSize={12} ml={2}>
                    Privacy Policy
                  </Text>
                </Link>
                <Link to="/cookie-policy">
                  <Text fontSize={12} ml={2}>
                    Cookie Policy
                  </Text>
                </Link>
              </Col>

              <Col md="3" className="mt-1 mb-0 mb-md-2 text-center">
                <ul className="social-icons">
                  <li>
                    <a href="/#" target="_blank">
                      <i className="icon icon-logo-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/#" target="_blank">
                      <i className="icon icon-logo-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/nvoyy/"
                      target="_blank"
                    >
                      <i className="icon icon-logo-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </CopyRightArea>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
